import { AvailableLanguages } from "./dropdownData";

export const heroData: Record<AvailableLanguages, Record<string, any>> = {
  english: {
    greeting: 'Hello, this is',
    name: "Angel Canela",
    job: 'Full-stack Software Engineer',
    intro: "I am a high-level Software Engineer with experience designing, testing and implementing software applications.",
    contact: "Contact",
  },
  spanish: {
    greeting: 'Hola, me llamo',
    name: "Angel Canela",
    job: 'Ingeniero de Software',
    intro: "Soy Ingeniero de Software con experiencia en el desarrollo de sistemas web.",
    contact: "Contacto"
  },
  japanese: {
    greeting: 'こんにちは',
    name: "Angel Canela",
    job: 'ソフトウェアエンジニア',
    intro: "私は、Web ベースのシステムの開発経験を持つソフトウェア エンジニアです.",
    contact: "連絡"
  }
};