import styled from "styled-components";

export const Container = styled.main`
  padding: 0 10%;
  overflow-x: hidden;

  @media (max-width: 960px) {
    padding: 0 2rem;
  }

  @media (max-width: 360px) {
    padding: 0 2rem;
  }
`;
